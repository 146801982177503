<template>
  <div class="evaluateRecord">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>评价记录</div>
      <div></div>
    </div>
    <div class="main">
      <div class="title"> {{demoName}}：</div>
      <ul class="recordList">
        <li @click="goInfo(item)" v-for="(item,index) in listData" :key="index">
          <span class="score">{{item.Result}}</span>
          <div class="liMid">
            <p>{{item.EvaluateTitle}}</p>
            <p>{{item.AddTime}}</p>
          </div>
          <div>
            <div class="shareBtn">
              <i class="el-icon-alifenxiang"></i>
              <span @click.stop="share(item)">分享</span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <van-dialog v-model="shareType" show-cancel-button>
      <p class="shareTitle">请手动复制 {{demoTitle}} 的链接：</p>
      <p class="shareInfo">{{shareUrl}}</p>
    </van-dialog>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      shareType: false,
      projectID: "", // 项目ID
      studentID: "", // 学生ID
      listData: [],
      demoName: "",
      StudentGroupID:"",//分组ID
      shareUrl: "",
      demoTitle: "",
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/Evaluate/EvaluateRecordList?projectID=' + this.projectID + "&userID=" + this.studentID+ "&studentGroupID=" + this.StudentGroupID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data)
        if (data.code == 200) {
          this.listData = data.data;
        }
      });
    },
    // 点击头部左上角返回
    back() {
      this.$router.push({ name: "demoIndex" });
    },
    // 点击列表进入详情
    goInfo(item) {
      this.$router.push({ name: "recordInfo", query: { evaluateRecordId: item.ID } });
    },
    // 点击分享按钮
    share(item) {
      this.shareType = true;
      this.demoTitle = item.EvaluateTitle;
      this.shareUrl = window.location.protocol + '//' + window.location.host + "/#/recordInfo?evaluateRecordId=" + item.ID;
      console.log(window.location)
    },

  },
  mounted() {
    this.demoName = this.cookies.kkGet("demoName");
    this.studentID = Cookies.get('studentID');
    this.projectID = this.cookies.kkGet("projectID");
    this.StudentGroupID=this.cookies.kkGet("StudentGroupID");
    this.initData();
  },
}
</script>

<style>
.evaluateRecord .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
}
.evaluateRecord .header i {
  margin-left: 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.evaluateRecord .header div:last-child {
  width: 30px;
}
.evaluateRecord .main {
  padding: 16px 18px 20px;
  margin-top: 44px;
  width: 100%;
  min-height: calc(100vh - 44px);
}
.evaluateRecord .main .recordList {
  margin-top: 16px;
}
.evaluateRecord .main .recordList li {
  width: 100%;
  height: 64px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(210, 210, 210, 100);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
}
.evaluateRecord .main .recordList li .score {
  color: #2acc9b;
  font-size: 24px;
  font-weight: bold;
}
.evaluateRecord .main .recordList li .shareBtn {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background: #3894ff;
  color: #fff;
  text-align: center;
  line-height: 30px;
}
.evaluateRecord .main .recordList li .shareBtn i {
  margin-right: 5px;
  font-size: 16px;
}
.evaluateRecord .main .recordList li .liMid p:first-child {
  font-size: 16px;
}
.evaluateRecord .main .recordList li .liMid p:last-child {
  font-size: 12px;
  color: #797979;
  margin-top: 2px;
}
.evaluateRecord .shareTitle {
  font-size: 18px;
  width: 100%;
  text-align: center;
}
.evaluateRecord .shareInfo {
  color: #706f6f;
  margin-top: 8px;
  width: 100%;
}
.evaluateRecord .van-dialog__content {
  padding: 15px 20px;
  word-break: break-all;
  word-wrap: break-word;
}
.evaluateRecord .van-dialog__confirm {
  color: #3894ff;
}
</style>